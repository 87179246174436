<style>
  .container {
    width: 100% !important;
    position: static !important;
  }

  .invoiceinput {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 4px;
    padding: 5px 10px;
  }

  .bold {
    font-weight: bold;
  }

  .paddingbottom {
    padding-bottom: 15px;
  }

  .margintop {
    margin-top: 25px;
  }


  mat-icon {
    position: relative;
    top: -2px;
  }

    mat-icon.info {
      font-size: 16px;
    }

  .dialogbutton {
    height: 42px;
    width: 100%;
    cursor: pointer;
  }
  /*
  .material-icons {
    font-size: 16px;
  }*/

  .mat-icon {
    height: 16px;
    width: 16px;
  }

  .fontsize80 {
    font-size: 80%;
  }

  .search {
    width: 90%;
    margin-right: 5px;
  }

  .check {
    color: green;
    margin-left: 5px;
  }

  .error {
    color: red;
    margin-left: 5px;
  }

  .net-group {
    border: 1px solid #b6b6b6;
    padding: 15px 15px 15px 15px;
    margin: 15px 0;
  }

  .error-message {
    color: red;
    font-size: 80%;
    margin-bottom: 15px;
  }

  .radioButtonMargin{
      margin-right:5px;
  }
  .amount-input
  {
      width:75px;
  }
</style>



<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h3 class="main-heading">SKAPA SAMLINGSFAKTURA <span *ngIf="invoice?.CreditInvoiceFor != null" style="color:red;">KREDITFAKTURA</span></h3>
    </div>
  </div>

  <div class="row" *ngIf="isAdmin && !isLoading && invoice.Id == 0">
    <div class="col-sm-12 col-lg-4">
      <div class="col-sm-12 col-lg-12">
        <mat-form-field style="width: 100%;" class="full-width">
          <input matInput placeholder="Hämta annan avsändare än admin" [(ngModel)]="memberSearchTerm" [id]="memberSearch" [name]="memberSearch" aria-label="Medlem" (input)="filterStates(memberSearchTerm)" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="memberSelected($event)">
            <mat-option *ngFor="let state of filteredStates | async" [value]="state.MemberNumber">
              <span *ngIf="state.CompanyName != null">{{ state.CompanyName }} ({{ state.OrgNumber }})</span>
              <span *ngIf="state.CompanyName == null">{{ state.FirstName + ' ' + state.LastName}} ({{ state.EmploymentNumber }})</span>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-lg-12" style="min-height: 48px;">
        <div style="display: block; position: relative; top: -10px;" *ngIf="selectedMemberObject != null">
          Vald avsändare:<br />
          <span *ngIf="selectedMemberObject?.CompanyName != null"><b>{{selectedMemberObject?.CompanyName}}</b> ({{selectedMemberObject?.OrgNumber}})</span>
          <span *ngIf="selectedMemberObject?.CompanyName == null"><b>{{selectedMemberObject?.FirstName + ' ' + selectedMemberObject?.LastName}}</b></span>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-6">
      <button color="accent" mat-raised-button style="position: relative; top: 11px;" (click)="getNewSender(invoice)" [disabled]="!canUpdateInvoiceDraft()">Hämta avsändardata och skapa faktura</button>
    </div>
  </div>

  <div class="row align-items-end" style="background-color: #f3f3f3; padding-top: 20px; padding-bottom: 30px;">
    <div class="col-sm-12 d-flex justify-content-start">
      <h2 class="accent">1. Fakturamottagare </h2>
      <mat-icon class="descriptionIcon" color="accent">assignment_ind</mat-icon>
    </div>

    <div class="col-sm-12 col-md-8 col-lg-6 select-customer">
      <mat-card class="select-customer-card">
        <mat-card-title>Välj en kund<mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Om mottagaren är privatperson så behöver inte referens finnas med">info</mat-icon></mat-card-title>

        <!-- Select customer -->
        <!--<div class="row">
        <mat-checkbox [disabled]="!canUpdateInvoice()" label="self" *ngIf="invoice" class="col-lg-6 col-sm-6 mb-2" [(ngModel)]="userIsInvoiceReceiver" (ngModelChange)="customerChangeToUser($event)">
          Jag vill skicka fakturan till mig själv
        </mat-checkbox>
      </div>-->
        <div class="row">
          <div class="col-8">

            <mat-form-field style="width:100%;">
              <mat-select [disabled]="!canUpdateInvoiceDraft() || !canUpdateGeneral() || companies.length == 0"
                          name="companies" placeholder="Ange mottagare"
                          [(ngModel)]="invoice?.Customer.Id" (ngModelChange)="customerChange($event)">
                <mat-option *ngFor="let c of companies" [value]="c.Id">
                  {{ c.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="col-4">
            <anms-big-input-action icon="person_add" color="accent" (action)="addCustomer()" [disabled]="!canUpdateGeneral()" matTooltip="Lägg till ny kund" matTooltipPosition="before"></anms-big-input-action>
            <anms-big-input-action *ngIf="invoice?.Customer.Id > 0" icon="edit" color="accent" (action)="editCustomer()" [disabled]="!canUpdateGeneral()" matTooltip="Redigera" matTooltipPosition="before"></anms-big-input-action>
          </div>
        </div>

        <!-- Select reference person -->
        <div class="row">
          <div class="col-8">
            <mat-form-field *ngIf="invoice.Customer.IsCompany" style="width:100%;">
              <mat-select [disabled]="!canUpdateInvoiceDraft() || !canUpdateGeneral() || invoice.Customer.Contacts == null || invoice.Customer.Contacts.length == 0"
                          placeholder="Din kontaktperson*"
                          [(ngModel)]="invoice?.Reference.Id"
                          (ngModelChange)="contactChange($event)">
                <mat-option *ngFor="let c of invoice.Customer.Contacts" [value]="c.Id">
                  {{ c.Name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <anms-big-input-action icon="person_add" color="accent"
                                   *ngIf="invoice?.Customer.Id > 0"
                                   (action)="addContact()"
                                   [disabled]="!canUpdateGeneral()"
                                   [hidden]="!invoice.Customer.IsCompany"
                                   matTooltip="Lägg till ny kontakt" matTooltipPosition="before">
            </anms-big-input-action>
            <anms-big-input-action *ngIf="invoice?.Reference.Id > 0" icon="edit" color="accent"
                                   (action)="editContact(invoice.Reference)"
                                   [disabled]="!canUpdateGeneral()"
                                   matTooltip="Redigera" matTooltipPosition="before">
            </anms-big-input-action>
          </div>
        </div>

      </mat-card>
    </div>

    <div *ngIf="invoice?.Customer.Id > 0" class="col-sm-12 col-md-8 col-lg-6">
      <mat-card style="height: 250px; width: 100%;">
        <div class="col customer-styling">
          <mat-icon color="accent">person</mat-icon>
          <mat-card-title> {{invoice.Customer.Name}} </mat-card-title>
        </div>
        <div class="col customer-styling">
          <mat-icon color="accent">home</mat-icon>
          <h4>
            {{invoice.Customer.Address}}
          </h4>
        </div>
        <div class="col customer-styling">
          <mat-icon color="accent">location_city</mat-icon>
          <h4>
            {{invoice.Customer.Zip}} {{invoice.Customer.City}}
          </h4>
        </div>
        <div class="col customer-styling">
          <mat-icon color="accent">public</mat-icon>
          <h4>
            {{invoice.Customer.Country?.Name}}
          </h4>
        </div>

        <h4 *ngIf="invoice.Customer.Co != null && invoice.Customer.Co != ''">
          c/o: {{invoice.Customer.Co}}
        </h4>
        <h4>
          {{invoice.Customer.Reference}}
        </h4>
      </mat-card>
    </div>



    <!--Fakturarader-->
    <div class="col-sm-12 d-flex justify-content-start">
      <h2 class="accent">2. Fakturering</h2>
      <mat-icon class="descriptionIcon" color="accent">description</mat-icon>
    </div>

    <div class="col-sm-12">
      <mat-card>
        <div>
          <mat-card-title class="mat-card-title" style="margin: 0 0 20px 0;">Hur vill du skicka fakturan? </mat-card-title>

          <div class="row">
            <div style="margin-bottom: 17px;" class="col-sm-12 col-md-8 col-lg-12">
              <div class="row" style="margin-bottom: 10px;">
                <div class="col-lg-6">
                  <span style="font-weight: bold; margin-right: 20px;">Skicka via:</span>

                  <mat-radio-group name="invoiceSendType" [(ngModel)]="invoice.InvoiceSendType" [disabled]="!canUpdateInvoiceDraft()">
                    <!--<mat-radio-button class="ml-2" [value]="2">Post</mat-radio-button>-->
                    <mat-radio-button class="ml-2 radioButtonMargin" [value]="1">E-post</mat-radio-button>
                    <mat-radio-button class="ml-2 radioButtonMargin" [value]="3">E-faktura<mat-icon color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Vissa företag/organisationer kräver e-faktura, t.ex. stat, kommun och landsting. Detta är inte att förväxla med PDF-faktura via e-post. Din kund kan ge dig all nödvändig information.">info</mat-icon></mat-radio-button>
                  </mat-radio-group>

                  <div class="col-lg-12">
                    <mat-form-field color="accent" class="col-lg-12" style="margin-top: 15px;" *ngIf="invoice.InvoiceSendType == 1">
                      <input type="email" [disabled]="!canUpdateInvoiceDraft() || !canUpdateGeneral()" matInput *ngIf="invoice" [(ngModel)]="invoice.RecieverEmail" name="email" ngModel email required placeholder="Mottagarens e-postadress" />
                    </mat-form-field>
                    <mat-form-field color="accent" class="col-sm-12" *ngIf="invoice.InvoiceSendType == 1" style="margin-bottom:20px;">
                      <input type="email" [disabled]="!canUpdateInvoiceDraft() || !canUpdateGeneral()" matInput [(ngModel)]="invoice.RecieverEmailCopy" placeholder="Fakturakopia e-postadress" />
                    </mat-form-field>
                    <div style="margin-top: 15px;" *ngIf="invoice.InvoiceSendType == 3">
                      <div *ngIf="invoice.Customer.GlnNumber">
                        <span>
                          Ditt angivna GLN nummer för denna kund är: <span style="font-weight: bolder;" class="accent">{{ invoice.Customer.GlnNumber }}</span>.

                          <anms-big-input-action *ngIf="invoice?.Customer.Id > 0" icon="edit" color="accent"
                                                 (action)="editCustomer()"
                                                 [disabled]="!canUpdateInvoiceDraft() || !canUpdateGeneral()"
                                                 matTooltip="Redigera" matTooltipPosition="before">
                          </anms-big-input-action>
                        </span>
                      </div>
                    </div> 
                  </div>
                  <!--<div class="col-lg-12" style="margin-top: 20px;" *ngIf="invoice.InvoiceSendType == 2">
                    <h4>Angiven mottagaradress:</h4>
                    <span class="bold">{{invoice.RecieverCompanyName}}</span><br />
                    <span *ngIf="invoice.RecieverAtt != null">Att: {{invoice.RecieverAtt}}<br /></span>
                    <span>{{invoice.RecieverAddress1}}</span> <span>{{invoice.RecieverAddress2}}</span><br />

                    <span>{{invoice.RecieverZip}} {{invoice.RecieverCity}}</span><br />
                    <span>{{invoice.Customer.Country?.Name}}</span><br /><br />

                    <p>
                      <span style="font-style: italic; margin-top: 15px;">
                        Om adressen inte stämmer, redigera din kund:
                        <anms-big-input-action *ngIf="invoice?.Customer.Id > 0" icon="edit" color="accent"
                                               (action)="editCustomer()"
                                               [disabled]="!canUpdateGeneral()"
                                               matTooltip="Redigera" matTooltipPosition="before">
                        </anms-big-input-action>
                      </span>
                    </p>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="row col-lg-12">
            <div style="margin-bottom: 17px;" class="col-sm-12 col-md-12 col-lg-12">
              <div class="row" style="margin-bottom: 10px;">
                <div class="col-lg-6">
                  <h4>Fakturamärkning <mat-icon color="accent" class="info" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Om din kund önskar specifik märkning på just denna faktura">info</mat-icon></h4>
                  <input class="invoiceinput" id="count" #myCounter='ngModel' type="text"
                         maxlength="25" [disabled]="!canUpdateGeneral()" matInput *ngIf="invoice"
                         [(ngModel)]="invoice.Marking" (ngModelChange)="wordCountMarking()" placeholder="Fakturamärkning" />
                  <mat-hint class="fontsize80">{{resultMarking}} | 25 tecken</mat-hint>
                </div>
                <div class="col-lg-6">
                  <div *ngIf="invoice.InvoiceSendType == 3">
                    <h4 class="bold">Unik fakturareferens <mat-icon color="accent" class="info" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Om din kund önskar specifik referens på just denna faktura">info</mat-icon></h4>
                    <input class="invoiceinput" type="text" [disabled]="!canUpdateInvoiceDraft() || !canUpdateGeneral()" matInput [(ngModel)]="invoice.ReferenceText" placeholder="Unik fakturareferens" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row margintop col-lg-12">
            <div class="col-lg-6">
              <h4>Betalvillkor:</h4>
              <mat-form-field>
                <mat-select [disabled]="!canUpdateInvoiceDraft() || !canUpdateGeneral()" [ngModel]="invoice.PaymentTerm" (ngModelChange)="invoice.PaymentTerm = $event; getDueDate();" name="paymentterm">
                  <mat-option *ngFor="let item of paymentTerms" value="{{item}}">{{item}} dagar</mat-option>
                </mat-select>
              </mat-form-field>
              <p class="fontsize80" *ngIf="canUpdateGeneral()">Beräknat förfallodatum: {{DueDate | date : 'yyyy-MM-dd'}}</p>
            </div>
            <div class="col-lg-6">

            </div>
          </div>
        </div>
      </mat-card>
    </div>

    <!--Specifikation-->

    <div class="col-sm-12 d-flex justify-content-start">
      <h2 class="accent">3. Specifikation</h2>
      <mat-icon class="descriptionIcon" color="accent">assignment</mat-icon>
    </div>
    

    <div class="col-sm-12 col-md-12">
      <mat-card class="col-sm-12">

        <!-- Provision -->
        <div class="row paddingbottom">
          <div class="row col-12">
            <div class="col-lg-3">
              <h4>Provision</h4>
            </div>
          </div>
          <div class="row col-12">
            <div class="col-lg-3">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input type="number" disabled matInput [ngModel]="commissionPercent" placeholder="Förinställd provision (procent)" id="commission" name="commission">
              </mat-form-field>
            </div>
            <div class="col-lg-3">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input type="number" [disabled]="!canUpdateGeneral()" matInput [ngModel]="newCommissionPercent" (ngModelChange)="updateCommission($event)" placeholder="Provision (procent) för denna faktura" id="newcommission" name="newcommission" #commission="ngModel">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <span style="font-size: 80%;">Om du tar provision/förmedlingsavgift för uppdrag du fakturerar en annan slutkund för anger du din procentsats här. Den förinställda procentsatsen kan du ändra i dina inställningar. Provisionen kommer dras från de fakturerade beloppen nedan.</span>
            </div>
          </div>
          <div class="col-lg-4 col-md-3">
          </div>
        </div>
        <!-- / Provision -->
        <!-- Global tjänstepension -->
        <div class="row paddingbottom">
          <div class="row col-12">
            <div class="col-lg-3">
              <h4>Tjänstepension</h4>
            </div>
          </div>
          <div class="row col-12">
            <div class="col-lg-3">
              <mat-form-field style="width: 100%" floatPlaceholder="never">
                <input type="number" matInput [disabled]="!canUpdateGeneral()" [ngModel]="globalPensionSavingsPercent" (ngModelChange)="globalPensionSavingsPercent = $event;updateGroupAmounts(globalPensionSavingsPercent, null)" placeholder="Pensionsavsättning (procent)" id="newcommission" name="pension" #pension="ngModel">
              </mat-form-field>
            </div>
            <div class="col-lg-3">
            </div>
            <div class="col-lg-6">
              <span style="font-size: 80%;">Här kan du ange en pensionsprocent för alla samtidigt om du önskar. Du kan även redigera detta per tillagd lönemottagare. 24,26 % av summan tillkommer i skatt. Om du tar provision räknas den av först.</span>
            </div>
          </div>
          <div class="col-lg-4 col-md-3">
          </div>
        </div>
        <!-- / Global tjänstepension -->
        <!-- Delning av service-avgiften -->
        <div class="row paddingbottom">
          <div class="row col-12">
            <div class="col-lg-3">
              <h4>Delning av service-avgiften vid egenanställning</h4>
            </div>
          </div>
          <div class="row col-12">
            <div class="col-lg-3">
              <mat-checkbox label="Använd delad serviceavgift" [(ngModel)]="invoice.ServiceFeeShare.IsActive" (ngModelChange)="updateInvoiceServiceFee()">
                Använd delad serviceavgift
              </mat-checkbox>
            </div>
            <div class="col-lg-3">
              <mat-form-field *ngIf="invoice?.ServiceFeeShare?.IsActive" style="width: 100%" floatPlaceholder="never">
                <input type="number" matInput [disabled]="!canUpdateGeneral()" [ngModel]="serviceFeeShare" (ngModelChange)="serviceFeeShare = $event;updateInvoiceServiceFee()" placeholder="Vår andel (procent)" id="newcommission" name="pension" #pension="ngModel" min="0" max="100">
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <span style="font-size: 80%;">Ange här hur stor andel av service-avgiften du vill att fakturamottagaren respektive lönemottagaren ska betala om du valt bemanning. Lönemottagaren betalar hela avgiften om inte annat anges.</span>
            </div>
          </div>
          <div class="col-lg-4 col-md-3">
          </div>
        </div>
        <!-- / Delning av service-avgiften -->

      </mat-card>
    </div>
    


    <div class="col-sm-12 col-md-12">
      <mat-card>
        <div *ngIf="invoice.Status.TechnicalName == 'Draft'">
          <div class="row">
            <div class="col-lg-4">
              <h4>Betalmottagare</h4>
              <div>Lägg till de som ska få lön / ersättning i ditt fakturaunderlag. </div>
              <div>
                <button mat-raised-button color="accent" [disabled]="invoice.Customer.Id == 0"  (click)="addPaymentRecievers()" style="margin-top:27px;height:42px;float:right;width:100%;">Lägg till betalmottagare</button>
              </div>
            </div>
          </div>
        </div>


        <div class="row time-group" [hidden]="invoice.InvoiceGroups.length == 0" *ngFor="let group of invoice?.InvoiceGroups">

          <span *ngIf="!group.PaymentReciever?.IsCompany" style="width:100%;">
            <b>{{group?.PaymentReciever?.FirstName + ' ' + group?.PaymentReciever?.LastName}} </b>
            <mat-icon (click)="editInvoiceCalendar(group)" style="float:right; cursor: pointer;">edit</mat-icon>
          </span>
          <span *ngIf="group.PaymentReciever?.IsCompany" style="width:100%;">
            <b>{{group.PaymentReciever?.CompanyName}}</b>
            <mat-icon (click)="editInvoiceCalendar(group)" style="float:right; cursor: pointer;">edit</mat-icon>
          </span>

          <span *ngIf="!group?.PaymentReciever?.IsCompany" class="angular-with-newlines description" style="width:100%;margin-bottom:0px;">Personnr: {{ group?.PaymentReciever?.PersonalNumber }} ({{group?.EmploymentType?.Name}})</span>
          <span *ngIf="group?.PaymentReciever?.IsCompany" class="angular-with-newlines description" style="width:100%;margin-bottom:0px;">Orgnr: {{ group?.PaymentReciever.OrgNumber }}</span>
          <span class="angular-with-newlines description" style="width:100%;margin-bottom:0px;"><i>{{group?.Description}}</i></span>
          <span *ngIf="group?.TimeReportStatus" class="angular-with-newlines description" style="font-size:12px;margin-bottom:20px;">Visa på faktura: {{group?.TimeReportStatus?.DisplayName}}</span>

          <span class="row time-row fontsize80" *ngFor="let i of group.InvoiceRows; let idx = index" style="width: 100%;">
            <div class="col">
              <span *ngIf="i.StartDate">{{i?.StartDate | date: 'yyyy-MM-dd'}}</span>
              <span *ngIf="i.HoursWorked">({{i?.HoursWorked}} h{{i?.MinutesWorked > 0 ? ' ' + i?.MinutesWorked + ' min' : ''}})</span>
              <span *ngIf="group.Type.Value != 4">({{i?.Vat}} % moms)</span>
              <span *ngIf="group?.Type?.Value == 4">{{group?.Amount}} kr ({{i?.Vat}} % moms)</span>
            </div>
          </span>

          <div class="col-lg-12" style="padding-left: 0px;">
            <span *ngIf="invoice.Customer.Country.Name != 'Sverige'" class="row-total-amount bold">
              Fakturerat belopp exkl ev nettoersättningar:
              <mat-form-field class="amount-input">
                <input type="number" matInput [disabled]="!canUpdateGeneral()" [(ngModel)]="group.Amount" (ngModelChange)="group.Amount = $event;onAmountUpdated();" placeholder="Belopp" id="amount" name="amount">
              </mat-form-field>
              {{invoice?.Currency?.Name}} (exkl. 0 % moms)
            </span>

            <span *ngIf="invoice.Customer.Country.Name == 'Sverige'" class="row-total-amount bold">
              Fakturerat belopp exkl ev nettoersättningar:
              <mat-form-field class="amount-input">
                <input type="number" matInput [disabled]="!canUpdateGeneral()" [(ngModel)]="group.Amount" (ngModelChange)="group.Amount = $event;onAmountUpdated();" placeholder="Belopp" id="amount" name="amount">
              </mat-form-field>
              {{invoice?.Currency?.Name}} (exkl. {{group?.Vat}} % moms)
            </span>
          </div>

          <!-- Pension savings single group -->
          <div class="row col-12" style="margin-top: 15px;">
            <div class="col-lg-3">
              <div class="payment-part-body">
                <mat-form-field>
                  <input type="number" matInput [disabled]="!canUpdateGeneral()" [ngModel]="group.PensionSavingsPercent" (ngModelChange)="group.PensionSavingsPercent = $event;updateSingleGroupPensionAmounts(group, group.PensionSavingsPercent, null)" placeholder="Pensionsavsättning (procent)" id="singlecommission" name="singlepension" #singlepension="ngModel">
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="payment-part-body">
                <mat-form-field>
                  <input class="text-input" matInput [disabled]="!canUpdateGeneral()" type="number" placeholder="Avsättning i SEK" [(ngModel)]="group.PensionSavingsAmount" (ngModelChange)="group.PensionSavingsAmount = $event;updateSingleGroupPensionAmounts(group, null, group.PensionSavingsAmount)">
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="payment-part-body">
                <mat-form-field>
                  <input class="text-input" matInput disabled placeholder="Total tjänstepension inkl skatt" [ngModel]="group.PensionSavingsTotalAmount" [(value)]="group.PensionSavingsTotalAmount">
                </mat-form-field>
              </div>
            </div>
            <div class="col-lg-3">
              <span style="font-size: 80%;">Ange hur mycket som ska avsättas för tjänstepension (24,26 % av summan tillkommer i skatt).</span>
            </div>
          </div>
          <!-- / Pension savings -->

          <div class="row col-12">
            <div class="col-sm-12 col-md-6 col-lg-4" *ngIf="invoice.Status.TechnicalName != 'UnderReview' || invoice.Status.Id != null || invoice.Status.TechnicalName != 'Draft'">
              <button mat-flat-button color="accent" [disabled]="!canUpdateGeneral()" (click)="editDeduction(group,'addedToInvoice')" style="height:42px;width:100%; cursor:pointer;">
                <mat-icon style="font-size: 16px;">add</mat-icon> Kostnader / utlägg / reseutlägg
              </button>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-4" *ngIf="invoice.Status.TechnicalName != 'UnderReview' || invoice.Status.Id != null || invoice.Status.TechnicalName != 'Draft'">
              <button mat-flat-button color="accent" [disabled]="!canUpdateGeneral()" (click)="editAllowance(group,'addedToInvoice')" style="height:42px;width:100%; cursor: pointer;">
                <mat-icon style="font-size: 16px;">add</mat-icon> Milersättning / traktamente
              </button>
            </div>
          </div>
          <!--Deductions list -->
          <div style="width: 100%;">
            <div class="col-12 fontsize80" [hidden]="!canUpdatePayout()">
              <div *ngFor="let c of group.Deductions">
                <div class="net-group">
                  <div style="display: inline-block; width: 100%;">
                    <div class="row">
                      <div class="col-lg-12">
                        <b>Kostnader / utlägg  </b><br />
                      </div>
                      <div class="col-lg-6">
                        {{c?.Name}}
                      </div>
                      <div class="col-lg-3">
                        <span class="row-total-amount">Belopp: {{c?.Amount | number : '1.2-2'}} SEK</span>
                      </div>
                      <div class="col-lg-3">
                        <div style="height: 24px; font-size: 24px; float: right; display: inline; cursor: pointer; margin-bottom: -5px;">
                          <button mat-icon-button [disabled]="!canUpdateGeneral()" (click)="editDeduction(group, '', c)" color="accent"><mat-icon>edit</mat-icon></button>
                        </div>
                        <div color="primary" style="height: 24px; font-size: 24px; float: right; display: inline; cursor: pointer;">
                          <button style=" margin-bottom: -5px;" mat-icon-button [disabled]="!canUpdateGeneral()" (click)="deleteDeduction(group,c)"><mat-icon>delete</mat-icon></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- / Deductions list -->
          <!-- Allowances list -->
          <div style="width: 100%;">
            <div [hidden]="!canUpdatePayout()">
              <div class="col-12 fontsize80" *ngFor="let c of group.Allowances">
                <div class="net-group">
                  <div style="display: inline-block; width: 100%;">
                    <div class="row">
                      <div class="col-lg-12">
                        <b>{{getAllowanceTypeName(c?.AllowanceType?.Id)}}</b><br />
                      </div>
                      <div class="col-lg-3">
                        <span style="float: left;" *ngIf="c.CustomReimbursementPerKm == 0">
                          {{c.Sum  | number : '1.2-2' }} SEK<br />
                        </span>
                        <span style="float: left;" *ngIf="c.CustomReimbursementPerKm > 0">
                          {{c.Sum  | number : '1.2-2' }} SEK (skattefritt, {{c.ReimbursementPerKm}} SEK/km)<br />
                        </span>
                      </div>
                      <div class="col-lg-3">
                        <span style="float: left; margin-left: 30px;">
                          {{c.TravelDateStart  | date: 'yyyy-MM-dd'}} {{c.TravelDateEnd != null ? ' - ' + (c.TravelDateEnd  | date: 'yyyy-MM-dd')  : ''}} {{ c.CarRegistrationNumber != null ? ' : ' + c.CarRegistrationNumber : '' }}
                        </span>
                      </div>
                      <div class="col-lg-3">
                        <div class="col" style="padding-left: 0px;">
                          <span class="row-total-amount">Belopp: {{c.Sum  | number : '1.2-2' }} SEK</span>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div color="accent" style="height: 24px; font-size: 24px; float: right; display: inline; cursor: pointer;">
                          <button mat-icon-button (click)="editAllowance(group, ' ', c)" color="accent"><mat-icon>edit</mat-icon></button>
                        </div>
                        <div color="" style="height: 24px; font-size: 24px; float: right; display: inline; cursor: pointer; ">
                          <button mat-icon-button (click)="deleteAllowance(group,c)"><mat-icon>delete</mat-icon></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- / Allowances list-->

          <div *ngIf="group.totalAmountInclNetExpenses > group.Amount" class="col-lg-12" style="padding-left: 0px;">
            <span *ngIf="invoice.Customer.Country.Name != 'Sverige'" class="row-total-amount bold">Fakturerat belopp inkl nettoersättningar: {{group.totalAmountInclNetExpenses | number: '1.0-2'}} {{invoice?.Currency?.Name}} (exkl. 0 % moms)</span>
            <span *ngIf="invoice.Customer.Country.Name == 'Sverige'" class="row-total-amount bold">Fakturerat belopp inkl nettoersättningar: {{group.totalAmountInclNetExpenses | number: '1.0-2'}} {{invoice?.Currency?.Name}} (exkl. {{group?.Vat}} % moms)</span>
          </div>


          <!-- Group amounts -->
          <div class="row col-lg-12">
            <div class="col-lg-6 fontsize80" style="padding: 10px 0 0 0;" *ngIf="newCommissionPercent > 0 || invoice.PensionSavingsFactor > 0 || group.PensionSavingsAmount > 0 || newCommissionPercent > 0 || invoice.PensionSavingsFactor > 0">
              <h4>Avdrag:</h4>
              <div class="col-lg-12" *ngIf="newCommissionPercent > 0">Provision: {{group?.commissionAmount | number: '1.0-2'}}</div>
              <div class="col-lg-12">Tjänstepension (inkl 24,26 % skatt): {{group?.PensionSavingsTotalAmount | number: '1.0-2'}}</div>
              <div class="col-lg-12" *ngIf="newCommissionPercent > 0 || group.PensionSavingsTotalAmount > 0">Lönegrundande belopp (innan ev. avgifter): {{group?.partialAmount | number: '1.0-2'}}</div>
            </div>
            <div class="col-lg-6" style="padding: 10px 0 0 0;">
              <span class="error-message">{{group.errorMessagePension}}</span>
            </div>
          </div>
          <!-- / Group amounts-->

        </div>

        <div class="row paddingbottom" style="border-top: 1px solid black; margin: 20px 0">
          <div class="col">
            <span *ngIf="hoursWorkedTotal > 0" style="float:right;margin-top:20px;">Totalt antal timmar: {{hoursWorkedTotal}}</span>
            <span style="float:right;margin-top:20px; font-weight: bold;">
              <span class="accent">Fakturerat belopp:</span> {{invoice?.TotalInvoiceAmount + invoice?.ServiceFee| number: '1.0-2'}}
              <mat-select [(ngModel)]="invoice.Currency.Id" style="width: 60px;" name="selectcurrency" (selectionChange)="currencyChanged()">
                <mat-option *ngFor="let item of currencies" value="{{item.Id}}">{{item.Name}}</mat-option>
              </mat-select>
              (ex moms)
            </span>
          </div>
          <div class="col-lg-12">
            <div *ngIf="invoice?.ServiceFee > 0" style="float:right;margin-top:20px;">
              Varav service-avgift: {{invoice?.ServiceFee | number: '1.0-2'}} SEK
            </div>
          </div>
        </div>

      </mat-card>
    </div>

    <div class="col-sm-12 pt-3 mt-5" style="margin-top:15px;">
      <mat-card class="col-sm-12">

        <mat-card-title style="margin-bottom:3px;">
          Meddelande till SAMgruppen
        </mat-card-title>

        <div style="margin-bottom:20px;">
          <mat-form-field color="accent" appearance="outline" class="col-sm-12 col-lg-10">
            <mat-label>Meddelande</mat-label>
            <textarea matInput [ngModel]="invoice?.Message" (ngModelChange)="invoice.Message = $event" rows="3" placeholder="Skriv meddelande här" id="message" name="message" md-select-on-focus></textarea>
          </mat-form-field>
          <button mat-stroked-button mat-raised-button class="col-sm-12 col-lg-2" *ngIf="(!canUpdateInvoiceDraft() && invoice.Id != 0) || invoice.Status.Name != 'Utkast'" [disabled]="invoice.Message == undefined || invoice.Message == ''" (click)="sendMessage(invoice.Message)">Skicka</button>
        </div>

        <div *ngFor="let message of invoice.Messages">
          <span>
            <i>({{message.Created | date: 'yyyy-MM-dd'}})</i>&nbsp;<b>{{message.CreatedBy}}:</b><br />
            <span class="angular-with-newlines">{{message.Text}}</span>
          </span>
        </div>
      </mat-card>
    </div>

    <!-- Provision -->
    <!--<div class="col-sm-12 d-flex justify-content-start">
    <h2 class="accent">4. Provision</h2>
    <mat-icon class="descriptionIcon" color="accent">assignment</mat-icon>
  </div>
  <div class="col-sm-12 col-md-12">
    <mat-card class="col-sm-12">
      <div class="row paddingbottom">
        <div class="row col-12">
          <div class="col-lg-3">
            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input type="number" disabled matInput [ngModel]="commissionPercent" placeholder="Förinställd provision (procent)" id="commission" name="commission">
            </mat-form-field>
          </div>
          <div class="col-lg-3">
            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input type="number" [disabled]="!canUpdateGeneral()" matInput [ngModel]="newCommissionPercent" (ngModelChange)="updateCommission($event);updateGroupAmounts()" placeholder="Provision (procent) för denna faktura" id="newcommission" name="newcommission" #commission="ngModel">
            </mat-form-field>
          </div>
          <div class="col-lg-6">
            <span style="font-size: 80%;">Om du tar provision/förmedlingsavgift för uppdrag du fakturerar en annan slutkund för anger du din procentsats här. Den förinställda procentsatsen kan du ändra i dina inställningar. Provisionen kommer dras från de fakturerade beloppen ovan.</span>
          </div>
        </div>
        <div class="col-lg-4 col-md-3">
        </div>
      </div>
    </mat-card>
  </div>-->
    <!-- Tjänstepension -->
    <!--<div class="col-sm-12 d-flex justify-content-start">
    <h2 class="accent">5. Tjänstepension</h2>
    <mat-icon class="descriptionIcon" color="accent">assignment</mat-icon>
  </div>
  <div class="col-sm-12 col-md-12">
    <mat-card class="col-sm-12">
      <div class="row paddingbottom">
        <div class="row col-12">
          <div class="col-lg-3">
            <mat-form-field style="width: 100%" floatPlaceholder="never">
              <input type="number" matInput [disabled]="!canUpdateGeneral()" [ngModel]="globalPensionSavingsPercent" (ngModelChange)="pensionSavingsPercent = $event;updateGroupAmounts()" placeholder="Pensionsavsättning (procent)" id="newcommission" name="pension" #pension="ngModel">
            </mat-form-field>
          </div>
          <div class="col-lg-3">

          </div>
          <div class="col-lg-6">
            <span style="font-size: 80%;">Ange hur stor del av det fakturerade beloppet som ska till för tjänstepension (24,26 % av summan tillkommer i skatt). Tjänstepensionen dras på summan ovan (om du tar provision räknas den av först).</span>
          </div>
        </div>
        <div class="col-lg-4 col-md-3">
        </div>
      </div>
    </mat-card>
  </div>-->


    <div class="col-lg-9">
    </div>
    <!--<mat-form-field class="col-3">
    <input type="text" [(ngModel)]="DiscountCode" matInput placeholder="Kampanjkod" disabled />
  </mat-form-field>-->
    <div class="col-lg-6"></div>
    <div class="col-lg-6 d-flex justify-content-end" style="margin-bottom:20px;">
      <mat-checkbox label="Härmed godkänner jag" *ngIf="invoice" class="col-lg-6 col-sm-6 mb-2" [(ngModel)]="invoice.termsAgreed">
        <a href="https://api.sampoolen.se/Documents/GeneralTermsOfUser.pdf" target="_blank" class="mr-2">Jag godkänner användaravtalet</a><br /> och de <a href="https://api.sampoolen.se/Documents/GeneralTermsRegistration.pdf" target="_blank">generella villkoren</a>
      </mat-checkbox>
    </div>

    <div class="col-lg-6">
      <button mat-flat-button color="primary" class="col-sm-6 col-lg-4" (click)="cancel()">Avbryt</button>
    </div>
    <div class="col-sm-6 buttonrow">
      <button mat-raised-button (click)="send(invoice)"
              [disabled]="!sendValidates() || !canUpdateInvoice()"
              color="accent">
        Skicka faktura
      </button>

      <button mat-stroked-button *ngIf="invoice.Status.TechnicalName != 'Draft'" (click)="saveDraft()" [disabled]="!canUpdateInvoiceDraft()">Spara</button>
      <button mat-stroked-button *ngIf="invoice.Status.TechnicalName == 'Draft'" (click)="saveDraft()" [disabled]="!canUpdateInvoiceDraft()">Spara utkast</button>
    </div>
    <div class="col-lg-12">
      <div style="float: right; color: red; font-style: italic; font-size: 80%;">{{ ErrorMessageSend }}</div>
    </div>
  </div>


</div>
