
<div class="title">
  <span *ngIf="isMasterAccount" (click)="editCompany(organization)" style="cursor:pointer;">
    {{organization?.Company?.Name}} <mat-icon>edit</mat-icon>
  </span>
  <span *ngIf="!isMasterAccount">
    {{organization?.Company?.Name}}
  </span>

  <mat-menu #appMenu="matMenu">
    <button mat-menu-item (click)="createUser()"><mat-icon>person_add</mat-icon> Ny administratör</button>
    <button mat-menu-item (click)="createSalaryReciever()"><mat-icon>person_add</mat-icon> Ny medarbetare</button>
  </mat-menu>

  <button mat-icon-button [matMenuTriggerFor]="appMenu">
    <mat-icon>more_vert</mat-icon>
  </button>

</div>


<div class="tab-container">
  <mat-tab-group class="custom-tab-group">
    <mat-tab label="Administratörer ({{customerData?.length}})">
      <div class="tab-content">

        <div class="">

          <div style="background-color: #FAFAFA; padding-left:24px;">
            <mat-form-field floatPlaceholder="never">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Sök administratör">
            </mat-form-field>
          </div>

          <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matSortActive="name" matSortDirection="asc" matSortDisableClear>

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Namn </mat-header-cell>
              <mat-cell *matCellDef="let row" (click)="editUser(row)" style="cursor:pointer;">
                <span><b>{{row?.FirstName + ' ' + row?.LastName | truncate:25}} </b></span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="roles">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Roller </mat-header-cell>
              <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm>
                <span *ngIf="row.OrganizationMasterAccount">Master, </span>{{ getRolesAsString(row?.Roles) }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="personalNumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Personnummer </mat-header-cell>
              <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{row?.PersonalNumber}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> E-postadress </mat-header-cell>
              <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{row?.Email | truncate:20}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="created">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Medlem sedan </mat-header-cell>
              <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{(row?.Created == '0001-01-01T00:00:00' ? '' : row?.Created) | date: 'yyyy-MM-dd'}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastLogin">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Senast inloggad </mat-header-cell>
              <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{(row?.LastLogin == '2017-01-01T00:00:00' ? '' : row?.LastLogin) | date: 'yyyy-MM-dd'}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="suspended">
              <th mat-header-cell *matHeaderCellDef style="border-bottom:none;">Spärrat</th>
              <td mat-cell *matCellDef="let row" style="border-bottom:none;">
                <button *ngIf="row?.Suspended" mat-icon-button style="float:right;">
                  <mat-icon>lock</mat-icon>
                </button>
              </td>
            </ng-container>


            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef style="border-bottom:none;">.</th>
              <td mat-cell *matCellDef="let row" style="border-bottom:none;">

                <button mat-icon-button style="float:right;" [matMenuTriggerFor]="menu">
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu" color="">
                  <button *ngIf="isAdmin" mat-menu-item (click)="addInvoice(row)"><mat-icon>note_add</mat-icon>Ny faktura</button>
                  <button *ngIf="isCompanyUser && isParentAccount" mat-menu-item (click)="editModal(row)"><mat-icon>edit</mat-icon>Redigera</button>
                  <button *ngIf="isAdmin" mat-menu-item (click)="edit(row)"><mat-icon>edit</mat-icon>Redigera</button>
                  <!--<button *ngIf="isAdmin || (isCompanyUser && isParentAccount)" mat-menu-item (click)="inactivate(row)"><mat-icon>person_off</mat-icon>Inaktivera</button>-->
                  <button *ngIf="isAdmin" mat-menu-item (click)="delete(row)"><mat-icon>delete</mat-icon>Radera</button>
                </mat-menu>
              </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
          </mat-table>

          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        </div>
      </div>
      <div style="font-size: 80%">
        <h3>Rollbeskrivning</h3>
        <p><strong>Master:</strong> Samma som nedan och dessutom redigera företagets uppgifter</p>
        <p><strong>Companyadmin:</strong> Har rättighet att skapa och redigera fakturor, lägga till kunder, lägga till andra administratörer</p>
      </div>

    </mat-tab>
    <mat-tab label="Medarbetare ({{paymentRecievers?.length}})">
      <div class="tab-content">

        <div style="background-color: #FAFAFA; padding-left:24px;">
          <mat-form-field floatPlaceholder="never">
            <input matInput (keyup)="applyFilter2($event.target.value)" placeholder="Sök användare">
          </mat-form-field>
        </div>

        <mat-table [dataSource]="dataSource2" matSort (matSortChange)="sortData2($event)" matSortActive="name" matSortDirection="asc" matSortDisableClear>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Namn </mat-header-cell>
            <mat-cell *matCellDef="let row" (click)="editPaymentReciever(row)" style="cursor:pointer;">
              <span><b>{{row?.FirstName + ' ' + row?.LastName | truncate:25}} </b></span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="personalNumber">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Personnummer </mat-header-cell>
            <mat-cell *matCellDef="let row" style="cursor:pointer;">
              <span>{{row?.PersonalNumber}}</span>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> E-postadress </mat-header-cell>
            <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{row?.Email == null ? 'dold' : ''}} {{row?.Email | truncate:20}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Redigera </mat-header-cell>
            <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm>

              <mat-menu #appMenu2="matMenu">
                <button mat-menu-item (click)="editPaymentReciever(row)"><mat-icon>edit</mat-icon> Redigera </button>
                <button mat-menu-item (click)="deletePaymentReciever(row)"><mat-icon>delete</mat-icon> Radera </button>
              </mat-menu>

              <button mat-icon-button [matMenuTriggerFor]="appMenu2">
                <mat-icon>more_vert</mat-icon>
              </button>

            </mat-cell>
          </ng-container>


          <!--<ng-container matColumnDef="personalNumber">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Personnummer </mat-header-cell>
    <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{row?.PersonalNumber}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> E-postadress </mat-header-cell>
    <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{row?.Email | truncate:20}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastLogin">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Senast inloggad </mat-header-cell>
    <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{(row?.LastLogin == '2017-01-01T00:00:00' ? '' : row?.LastLogin) | date: 'yyyy-MM-dd'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="suspended">
    <th mat-header-cell *matHeaderCellDef style="border-bottom:none;">Spärrat</th>
    <td mat-cell *matCellDef="let row" style="border-bottom:none;">
      <button *ngIf="row?.Suspended" mat-icon-button style="float:right;">
        <mat-icon>lock</mat-icon>
      </button>
    </td>
  </ng-container>


  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef style="border-bottom:none;">.</th>
    <td mat-cell *matCellDef="let row" style="border-bottom:none;">

      <button mat-icon-button style="float:right;" [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu" color="">
        <button *ngIf="isAdmin" mat-menu-item (click)="addInvoice(row)"><mat-icon>note_add</mat-icon>Ny faktura</button>
        <button *ngIf="isCompanyUser && isParentAccount" mat-menu-item (click)="editModal(row)"><mat-icon>edit</mat-icon>Redigera</button>
        <button *ngIf="isAdmin" mat-menu-item (click)="edit(row)"><mat-icon>edit</mat-icon>Redigera</button>-->
          <!--<button *ngIf="isAdmin || (isCompanyUser && isParentAccount)" mat-menu-item (click)="inactivate(row)"><mat-icon>person_off</mat-icon>Inaktivera</button>-->
          <!--<button *ngIf="isAdmin" mat-menu-item (click)="delete(row)"><mat-icon>delete</mat-icon>Radera</button>
    </mat-menu>
  </td>-->
          <!--</ng-container>-->

          <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns2;">
          </mat-row>
        </mat-table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

      </div>
    </mat-tab>
  </mat-tab-group>
</div>









