
<style>
  .frame::-webkit-scrollbar {
    -webkit-appearance: none;
  }

    .frame::-webkit-scrollbar:vertical {
      width: 11px;
    }

    .frame::-webkit-scrollbar:horizontal {
      height: 11px;
    }

  .frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }

  .frame::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }

  /*No footer in dialog fix */
  mat-dialog-content {
    overflow: initial !important;
  }

  .mat-dialog-container {
    height: auto !important;
  }

  .errormessage {
    color: red;
    font-size: 80%;
    font-style: italic;
  }

  .warning-box {
    border: 1px solid red;
    background-color: #ffe5e5; /* Light red background */
    padding: 20px;
    margin: 10px auto 20px auto;
    font-weight: bold;
  }
</style>

<h1 mat-dialog-title *ngIf="isNewUser" style="margin-top: 5px;">Ny lönemottagare</h1>
<h2 mat-dialog-title *ngIf="!isNewUser" style="margin-top: 5px;">Redigera användare</h2>

<div *ngIf="personalNumberError" style="color:red;margin-bottom:15px;">{{personalNumberError}}</div>

<form #personForm="ngForm" novalidate>
  <!-- Personnummer -->
  <mat-form-field style="width:100%;">
    <mat-label>Personnummer {{personalNumberError}}</mat-label>
    <input matInput type="text"
           (ngModelChange)="pNumberValidCheck($event)"
           [(ngModel)]='user.PersonalNumber'
           [disabled]="!isNewUser"
           placeholder="ÅÅÅÅMMDDNNNN (12 siffror utan bindestreck)"
           required
           name="Personnummer">
    <mat-icon *ngIf="!pNrValid && isNewUser" color="accent" matSuffix matTooltipPosition="before" matTooltip="Personen ej verifierad" style="color:red;">close</mat-icon>
  </mat-form-field>

  <span *ngIf="!pNrValid" class="errormessage">{{errorMessagePnr}}</span>

  <mat-form-field style="width:100%;">
    <input matInput type="text" [disabled]="true" ngModel [(ngModel)]='user.FirstName' placeholder="Förnamn" name="firstname">
  </mat-form-field>

  <mat-form-field style="width:100%;">
    <input matInput type="text" [disabled]="true" ngModel [(ngModel)]='user.LastName' placeholder="Efternamn" name="lastname">
  </mat-form-field>

  <mat-form-field *ngIf="!userExistsInTenant && !user.HasLoggedIn" style="width: 100%;">
    <input matInput type="email" #correctEmail="ngModel" [(ngModel)]="user.Email" placeholder="Epost" name="Email" required email>
    <mat-icon *ngIf="correctEmail.invalid && correctEmail.touched" matSuffix color="accent">warning</mat-icon>
  </mat-form-field>

  <mat-form-field *ngIf="!userExistsInTenant && !user.HasLoggedIn" style="width:100%;">
    <input matInput type="text" ngModel [(ngModel)]='user.ClearingNumber' placeholder="Clearingnummer" name="clearing">
  </mat-form-field>

  <mat-form-field *ngIf="!userExistsInTenant && !user.HasLoggedIn" style="width:100%;">
    <input matInput type="text" ngModel [(ngModel)]='user.AccountNumber' placeholder="Bankkontonummer" name="AccountNumber">
  </mat-form-field>

  <!--<mat-form-field style="width: 100%;">
    <mat-select [(ngModel)]="user.RoleIds"
                placeholder="Role"
                (ngModelChange)="user.RoleIds = $event"
                name="roles"
                #roleSelect="ngModel"
                multiple>
      <mat-option *ngFor="let role of roles" [value]="role.Id">{{role.Name}}</mat-option>
    </mat-select>
  </mat-form-field>-->

</form>

<div>{{errorMessage}}</div>

<div *ngIf="userExistsInTenant" style="margin-top: 15px;">
  Spara för att koppla till medarbetarlistan.
</div>

<button *ngIf="user.HasLoggedIn == null || !user.HasLoggedIn" mat-raised-button style="float: right;margin-top:15px;" color="accent"
        [disabled]="!personForm.valid || (!pNrValid && isNewUser) || (correctEmail != null && !correctEmail.valid)"
        (click)="save(user)">
  <mat-icon>check</mat-icon>
  &nbsp;Spara&nbsp;
</button>&nbsp;

