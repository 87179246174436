import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, catchError, map, tap } from 'rxjs/operators';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { CompanyService } from '../../core/services/CompanyService';
import { AuthenticationService } from '../../core/services/AuthenticationService';
import { UserService } from '../../core/services/UserService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { OrganizationService } from '../../core/services/OrganizationService';
import { DataSource } from '@angular/cdk/collections';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { EditCustomerDialogComponent } from '../shared/customer/EditCustomerDialog.Component';
import { ICompany } from '../../core/interfaces/ICompany';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatPaginator, MatTableDataSource, MatSortModule, MatSort, Sort } from '@angular/material';
import { finalize } from "rxjs/internal/operators/finalize";
import { IAccountSettings } from '../../core/interfaces/user/IAccountSettings';
import { Router, ActivatedRoute, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EditAccountDialogComponent } from '../../shared/account/EditAccountDialog.Component';
import { IOrganization } from '../../core/interfaces/organization/IOrganization';
import { EditCompanyDialogComponent } from '../../shared/company/EditCompanyDialog.Component';
import { EditUserModalComponent } from '../../static/users/edit-user-modal/edit-user-modal.component';
import { SalaryRecieverEditComponent } from '../../static/users/edit-salaryreciever-modal/edit-salaryreciever-modal.component';

@Component({
  selector: 'organization-modal',
  templateUrl: './organization-modal.component.html',
  styleUrls: ['./organization-modal.component.scss']
})
export class OrganizationModalComponent implements OnInit, OnDestroy {

  public organization: IOrganization = null;
  public isParentAccount: boolean = false;
  public isCompanyUser: boolean = false;
  public isAdmin: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  displayedColumns = ['name', 'roles', 'personalNumber', 'email', 'created', 'lastLogin', 'suspended'];
  displayedColumns2 = ['name','personalNumber','email','edit'];


  customerData: Array<any>;
  sortedData;
  dataSource;
  downloadingReport: boolean = false;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  paymentRecievers: Array<any>;
  sortedData2;
  dataSource2;
  downloadingReport2: boolean = false;
  @ViewChild(MatPaginator, { static: false }) paginator2: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort2: MatSort;
  
  public isMasterAccount: boolean = false;

  constructor(public store: Store<any>,
      public snackBar: MatSnackBar,
      private companyService: CompanyService,
      private userService: UserService,
    private invoiceService: InvoiceService,
    private organizationService: OrganizationService,
    private authenticationService: AuthenticationService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
      private loadingService: LoadingBarService,
      public dialog: MatDialog) {
  }

  ngOnInit() {
    this.loadingService.start();
    this.isAdmin = this.userService.userHasRole('admin');
    this.isParentAccount = this.userService.userIsParentAccount();
    this.isCompanyUser = this.userService.userHasRole('companyuser');
    this.isMasterAccount = this.userService.isOrganizationMasterAccount();
    this.organization = this.data.organization;

    this.getUsers(this.organization.Id);
    this.getPaymentRecievers(this.organization.Id);
  }

  applyFilter(filterValue: string) {
      
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
      this.dataSource.filter = filterValue;
  }

  applyFilter2(filterValue: string) {

    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource2.filter = filterValue;
  }
  
  getExcelReport()
  {
      this.downloadingReport = true;

      this.companyService.GetCompanyUsersFile(1)
          .subscribe(x => {
              // It is necessary to create a new blob object with mime-type explicitly set
              // otherwise only Chrome works like it should
              var newBlob = new Blob([x], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

              // IE doesn't allow using a blob object directly as link href
              // instead it is necessary to use msSaveOrOpenBlob
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(newBlob);
                  return;
              }

              // For other browsers: 
              // Create a link pointing to the ObjectURL containing the blob.
              const data = window.URL.createObjectURL(newBlob);

              var link = document.createElement('a');
              link.href = data;
              link.download = "Medlemslista.xlsx";
              // this is necessary as link.click() does not work on the latest firefox
              link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

              setTimeout(function () {
                  // For Firefox it is necessary to delay revoking the ObjectURL
                  window.URL.revokeObjectURL(data);
                  link.remove();
              }, 100);

              this.downloadingReport = false;
          });
  }

  editCompany(org: IOrganization) {

    const dialogRef = this.dialog.open(EditCompanyDialogComponent, {
      width: '400px',
      height: '750px',
      data: {
        id: org.Company.Id,
        customer: org.Company
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.authenticationService.setCurrentUserOrganizationCompany(result);
    });
  }

  getRolesAsString(roles: { Name: string }[]): string {
    return roles ? roles.map(role => role.Name).join(', ') : '';
  }

  sortData(sort: Sort) {
      const data = this.customerData.slice();

      if (!sort.active || sort.direction == '') {
          this.sortedData = data;
          return;
      }

      this.sortedData = data.sort((a, b) => {
          let isAsc = sort.direction == 'asc';
          switch (sort.active) {
              case 'name': return this.compare(a.FirstName, b.FirstName, isAsc);
              case 'companynamename': return this.compare(a.CompanyName, b.CompanyName, isAsc);
              case 'memberNumber': return this.compare(a.MemberNumber, b.MemberNumber, isAsc);
              case 'personalNumber': return this.compare(a.PersonalNumber, b.PersonalNumber, isAsc);
              case 'email': return this.compare(a.Email, b.Email, isAsc);
              case 'created': return this.compare(a.Created, b.Created, isAsc);
              case 'lastLogin': return this.compare(a.LastLogin, b.LastLogin, isAsc);
              default: return 0;
          }
      });
      
      this.dataSource.data = this.sortedData;
  }

  sortData2(sort: Sort) {
    //const data = this.customerData.slice();

    //if (!sort.active || sort.direction == '') {
    //  this.sortedData = data;
    //  return;
    //}

    //this.sortedData = data.sort((a, b) => {
    //  let isAsc = sort.direction == 'asc';
    //  switch (sort.active) {
    //    case 'name': return this.compare(a.FirstName, b.FirstName, isAsc);
    //    case 'companynamename': return this.compare(a.CompanyName, b.CompanyName, isAsc);
    //    case 'memberNumber': return this.compare(a.MemberNumber, b.MemberNumber, isAsc);
    //    case 'personalNumber': return this.compare(a.PersonalNumber, b.PersonalNumber, isAsc);
    //    case 'email': return this.compare(a.Email, b.Email, isAsc);
    //    case 'created': return this.compare(a.Created, b.Created, isAsc);
    //    case 'lastLogin': return this.compare(a.LastLogin, b.LastLogin, isAsc);
    //    default: return 0;
    //  }
    //});

    //this.dataSource.data = this.sortedData;
  }

  compare(a, b, isAsc) {
      console.log((a < b ? -1 : 1) * (isAsc ? 1 : -1));
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  ngOnDestroy(): void {
  }

  getUsers(organizationId: number) {

    this.userService.getOrganizationUsers(organizationId)
          .subscribe(value => this.onUsersRetrieved(value),
            error => console.log(error)
          );
  }

  getPaymentRecievers(organizationId: number) {
    
    this.organizationService.getPaymentRecievers(organizationId)
      .subscribe(value => this.onPaymentRecieversRetrieved(value),
        error => console.log(error)
      );
  }


  addInvoice(user: any)
  {    
      this.invoiceService.createInvoice({ User: { Id: user.User.Guid } }, 'Fakturan skapades!', undefined)
          .subscribe(value => this.onInvoiceCreated(value),
          error => console.log(error)
          );
  }

  delete(user: any) {
    
      var result = confirm("Vill du radera " + user.FirstName + " " + user.LastName + "?");
      if (result) {
          this.userService.deleteUser(user.User.Guid)
              .subscribe(value => this.onInvoiceDeleted(user.Guid),
              error => console.log(error)
              );
      }
      
  }

  deletePaymentReciever(user: any) {
    debugger
    
    var result = confirm("Vill du radera betalmottagaren " + user.FirstName + " " + user.LastName + "?");
    if (result) {
      this.organizationService.deletePaymentReciever(this.organization.Id, user.Id)
        .subscribe(value => this.getPaymentRecievers(this.organization.Id),
          error => console.log(error)
        );
    }

  }

  onInvoiceDeleted(id: number)
  {
      
      for (var i = 0; i < this.customerData.length; i++)
      {
          if (this.customerData[i].Id == id)
          {
              this.customerData.splice(i, 1);
          }
      }

      this.dataSource = new MatTableDataSource(this.customerData);
  }
    
  onInvoiceCreated(response: any)
  {
      this.router.navigate(['/invoice-tool', response.Id]);
      console.log(response);
  }

  onUsersRetrieved(users: any)
  {
      this.customerData = users;
      this.dataSource = new MatTableDataSource(this.customerData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      
      // Set up custom search
      this.dataSource.filterPredicate = function (data, filter: string): boolean {
          return (data.FirstName == undefined ? '' : data.FirstName).toLowerCase().includes(filter) ||
              (data.FirstName + ' ' + data.LastName).toLowerCase().includes(filter) ||
              data.MemberNumber.toString().includes(filter) ||
              (data.PersonalNumber == undefined ? '' : data.PersonalNumber).toLowerCase().includes(filter) ||
              (data.Email == undefined ? '' : data.Email).toLowerCase().includes(filter) ||
              (data.CompanyName == undefined ? '' : data.CompanyName).toLowerCase().includes(filter);
      };

      this.loadingService.complete();
  }

  onPaymentRecieversRetrieved(users: any) {

    console.log('Lönemottagare')
    console.log(users);

    this.paymentRecievers = users;

    this.dataSource2 = new MatTableDataSource(this.paymentRecievers);
    this.dataSource2.sort = this.sort2;
    this.dataSource2.paginator = this.paginator2;

    // Set up custom search
    this.dataSource2.filterPredicate = function (data, filter: string): boolean {
      return (data.FirstName == undefined ? '' : data.FirstName).toLowerCase().includes(filter) ||
        (data.FirstName + ' ' + data.LastName).toLowerCase().includes(filter) ||
        (data.PersonalNumber == undefined ? '' : data.PersonalNumber).toLowerCase().includes(filter) ||
        (data.Email == undefined ? '' : data.Email).toLowerCase().includes(filter);
    };
  }

  edit(user: any) {
    var newWindow = window.open("#/users/" + user.User.Guid);
  }

  editModal(user: any) {

    const dialogRef = this.dialog.open(EditAccountDialogComponent, {
      width: '350px',
      height: '440px',
      data: {
        id: user.User.Guid
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUsers(this.organization.Id);
    });

  }

  editUser(user: any) {

    const dialogRef = this.dialog.open(EditUserModalComponent, {
      width: '350px',
      height: '500px',
      data: {
        id: 0,
        user: user
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUsers(this.organization.Id);
    });

  }

  createUser() {

      const dialogRef = this.dialog.open(EditUserModalComponent, {
        width: '350px',
        height: '500px',
        data: {
          id: 0,
          organizationId: this.organization.Id
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.getUsers(this.organization.Id);
      });
    }

  createSalaryReciever() {

    const dialogRef = this.dialog.open(SalaryRecieverEditComponent, {
      width: '350px',
      height: '500px',
      data: {
        id: 0,
        headline: 'Ny lönemottagare',
        organizationId: this.organization.Id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getPaymentRecievers(this.organization.Id);
    });
  }

  editPaymentReciever(user: any) {

    const dialogRef = this.dialog.open(SalaryRecieverEditComponent, {
      width: '350px',
      height: '500px',
      data: {
        id: 0,
        user: user
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getUsers(this.organization.Id);
    });

  }
    
}
