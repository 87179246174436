
<style>
  .fix-static-bug {
    width: 100% !important;
    position: static !important;
  }

  .groups {
    padding-left: 3px;
  }

  .person {
      padding-left: 3px;
  }
 
</style>

<div class="fix-static-bug">

  <div class="container" style="margin-bottom:15px;margin-top:15px;">
    <div class="row">
      <div class="d-none d-lg-block">
        <div class="title-invoice">
          <h3 *ngIf="isAdmin && invoiceData?.length > 0" class="main-heading">FAKTUROR ({{listLength}})</h3>
          <h3 *ngIf="!isAdmin">FAKTUROR ({{listLength}})</h3>
        </div>
      </div>
    </div>
  </div>

  <div class="container backgroundcolor">

    <div class="row">
      <div class="col-12 entry" style="margin-top: 0px;">

        <div class="example-container mat-elevation-z1">

          <div class="title-mobile">
            <span *ngIf="isAdmin && invoiceData?.length > 0">FAKTUROR ({{listLength}})</span>
            <span *ngIf="!isAdmin">FAKTUROR ({{listLength}})</span>
            <mat-spinner *ngIf="isLoading" diameter="30"></mat-spinner>

          </div>

          <div style="padding-left:24px; background: white;">
            <mat-form-field floatPlaceholder="never">
              <input matInput [(ngModel)]="search"
                     type="text" name="search" id="search"
                     (ngModelChange)="this.searchUpdate.next($event)" placeholder="Sök">
            </mat-form-field>
          </div>

          <div class="invoice-table">
            <mat-table [dataSource]="dataSource" [class.isMobile]="isMobile" matSort (matSortChange)="sortData($event)" matSortActive="name" matSortDirection="asc" matSortDisableClear>

              <ng-container matColumnDef="file">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> </mat-header-cell>
                <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm>
                  <a *ngIf="row?.InvoiceDocument.Url != null && row?.InvoiceDocument && row.Status.TechnicalName != 'UnderReview'" href="{{row?.InvoiceDocument.Url}}" title="{{row?.InvoiceNumber}}" target="_blank"><mat-icon>picture_as_pdf</mat-icon></a>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="icon">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> </mat-header-cell>
                <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm>
                  <span *ngIf="row?.Type?.TechnicalName == 'ParentInvoice' || row?.ParentInvoice" class="groups"><mat-icon>groups</mat-icon></span>
                  <span *ngIf="row?.Type?.TechnicalName == 'CommissionCashInvoice'" class="groups"  matTooltipPosition="after" area-label="Hover to display info" matTooltip="Detta är en kontantfaktura för provision."><mat-icon>compare_arrows</mat-icon></span>
                  <span *ngIf="row?.Type?.TechnicalName == 'SubInvoice'" class="groups" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Detta är underlag för utbetalning."><mat-icon>person</mat-icon></span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="isAdmin" matColumnDef="invoicenumber">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Faktura </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <span class="mobile-label">Faktura:&nbsp;</span>
                  <a *ngIf="row.InvoiceDocument && row.Status.TechnicalName != 'UnderReview'" href="{{row?.InvoiceDocument.Url}}" title="{{row?.InvoiceNumber}}" target="_blank">{{row?.InvoiceNumber}}</a>
                  <span *ngIf="!row?.InvoiceDocument || row.Status.TechnicalName == 'UnderReview'">{{row?.InvoiceNumber}}</span>
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="isAdmin" matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <span class="mobile-label">Id:&nbsp;</span>
                  {{row?.Id}}
                </mat-cell>
              </ng-container>

              <ng-container *ngIf="!isAdmin" matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Faktura </mat-header-cell>
                <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm>
                  <span class="mobile-label">Faktura:&nbsp;</span>
                  <a *ngIf="row.InvoiceDocument && row.Status.TechnicalName != 'UnderReview'" href="{{row?.InvoiceDocument.Url}}" title="{{row?.InvoiceNumber}}" target="_blank">{{row?.InvoiceNumber}}</a>
                  <span *ngIf="!row?.InvoiceDocument || row.Status.TechnicalName == 'UnderReview'">{{row?.InvoiceNumber}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="created">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm>Skickad </mat-header-cell>
                <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm>
                  <span class="mobile-label">Skickad:&nbsp;</span>{{row?.InvoiceSentByUser | slice:0:10}}
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="dueDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Förfaller </mat-header-cell>
                <mat-cell *matCellDef="let row"><span class="mobile-label">Förfaller:&nbsp;</span>{{row?.PaymentDue | slice:0:10}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="userName">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm>Användare</mat-header-cell>
                <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm>
                  
                  <a href="#/users/{{row?.UserSettings?.Guid}}" target="_blank">
                    <span class="mobile-label">Användare:&nbsp;</span>
                    <span>{{row?.UserSettings?.FirstName}} {{row?.UserSettings?.LastName}}</span>
                  </a>

                </mat-cell>

              </ng-container>

              <ng-container matColumnDef="customerName">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm>Kundnamn </mat-header-cell>
                <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm>
                  <span *ngIf="row?.Customer?.Name != null">{{row?.Customer?.Name}}</span>
                  <span *ngIf="row?.Customer?.Name == null && row?.RecieverCompanyName != null">{{row?.RecieverCompanyName}}</span>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="payoutstatus">
                <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm>Lönestatus </mat-header-cell>
                <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm>
                  <span class="mobile-label">Lönestatus:&nbsp;</span>
                  <span *ngIf="!isAdmin">{{row?.PayoutStatus?.Name}}</span>
                  <mat-select *ngIf="isAdmin" placeholder="Lönestatus" [(ngModel)]="row.PayoutStatus.Id" style="width: 130px;" (ngModelChange)="payoutStatusChange(row)">
                    <mat-option *ngFor="let s of payoutStatuses" [value]="s.Id">
                      {{ s?.Name }}
                    </mat-option>
                  </mat-select>
                </mat-cell>
              </ng-container>

              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Fakturastatus</mat-header-cell>
                <mat-cell *matCellDef="let row">
                  <span class="mobile-label">Fakturastatus:&nbsp;</span>
                  <span *ngIf="!isAdmin">{{row?.Status?.Name}}</span>
                  <mat-select *ngIf="isAdmin" [disabled]="row?.SubInvoice" placeholder="Fakturastatus" [(ngModel)]="row.Status.Id" style="width: 130px;" (ngModelChange)="statusChange(row)">
                    <mat-option *ngFor="let s of statuses" [value]="s.Id">
                      {{ s?.Name }}
                    </mat-option>
                  </mat-select>
                </mat-cell>
              </ng-container>

              
              <ng-container *ngIf="isAdmin" matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef style="border-bottom:none;" fxShow="false" fxShow.gt-sm>.</th>
                <td class="options" mat-cell *matCellDef="let row" style="border-bottom:none;" fxShow="false" fxShow.gt-sm>
                  <span class="mobile-label">Fler alternativ:&nbsp;</span>
                  <button mat-icon-button color="default" style="float:right;" [matMenuTriggerFor]="menu">
                    <mat-icon color="accent">reorder</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" color="accent">
                    <button mat-menu-item (click)="editInvoice(row)"><mat-icon color="accent">edit</mat-icon>Redigera</button>
                    <button mat-menu-item (click)="openAdminView(row)"><mat-icon color="accent">swap_horiz</mat-icon>Admin</button>
                    <button mat-menu-item (click)="showSummary(row)"><mat-icon color="accent">money</mat-icon>In- och utbetalning</button>
                    <button mat-menu-item (click)="creditInvoice(row)" [disabled]="row?.InvoiceNumber == null"><mat-icon color="accent">money_off</mat-icon>Kreditera</button>
                    <button mat-menu-item (click)="openWorkCertificateView(row)" [disabled] ="row?.PayoutStatus.TechnicalName != 'PaidOut'"><mat-icon color="accent">drafts</mat-icon>Arbetsgivarintyg</button>
                    <button mat-menu-item (click)="copyInvoice(row)" [disabled]="row?.SubInvoice || row?.Status?.TechnicalName == 'Paired'"><mat-icon color="accent">file_copy</mat-icon>Kopiera till ny faktura</button>
                    <button mat-menu-item (click)="deleteInvoice(row)" [disabled]="!row.SubInvoice && row?.Status?.Name != 'Utkast'"><mat-icon color="accent">delete</mat-icon>Radera</button>
                  </mat-menu>
                </td>
              </ng-container>

              <!--Not admin-->
              <ng-container *ngIf="!isAdmin" matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef style="border-bottom:none;" fxShow="false" fxShow.gt-sm>.</th>
                <td class="options" mat-cell *matCellDef="let row" style="border-bottom:none;" fxShow="false" fxShow.gt-sm>
                  <button mat-icon-button color="default" [matMenuTriggerFor]="menu">
                    <mat-icon color="accent">reorder</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" color="accent">
                    <button mat-menu-item (click)="editInvoice(row)"><mat-icon color="accent">edit</mat-icon>Redigera</button>
                    <button mat-menu-item (click)="copyInvoice(row)" [disabled]="row?.SubInvoice || row?.Status?.TechnicalName == 'Paired'"><mat-icon color="accent">file_copy</mat-icon>Kopiera till ny faktura</button>
                    <button mat-menu-item (click)="creditInvoice(row)" [disabled]="!checkMenuOption(row, 'credit')"><mat-icon color="accent">money_off</mat-icon>Kreditera</button>
                    <button mat-menu-item (click)="deleteInvoice(row)" [disabled]="row?.Status?.Name != 'Utkast'"><mat-icon color="accent">delete</mat-icon>Radera</button>
                  </mat-menu>
                </td>
              </ng-container>

              <!-- Mobile Rows, not admin -->
              <div class="mobile-class">
                <ng-container *ngIf="!isAdmin" matColumnDef="customerNameMobile">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide>Redigera</mat-header-cell>
                  <mat-cell *matCellDef="let row" class="mobile-class mobile-label-customer" fxHide.gt-sm>
                    {{row?.Customer?.Name}}
                    <button mat-icon-button color="default" [matMenuTriggerFor]="menu">
                      <mat-icon color="accent">reorder</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" color="accent">
                      <button mat-menu-item (click)="editInvoice(row)"><mat-icon>edit</mat-icon>Redigera</button>
                      <button mat-menu-item (click)="copyInvoice(row)" [disabled]="row?.SubInvoice || row?.Status?.TechnicalName == 'Paired'"><mat-icon>file_copy</mat-icon>Kopiera till ny faktura</button>
                      <button mat-menu-item (click)="creditInvoice(row)" [disabled]="!checkMenuOption(row, 'credit')"><mat-icon color="accent">money_off</mat-icon>Kreditera</button>
                      <button mat-menu-item (click)="deleteInvoice(row)" [disabled]="row?.Status?.Name != 'Utkast'"><mat-icon>delete</mat-icon>Radera</button>
                    </mat-menu>
                  </mat-cell>
                </ng-container>

                <ng-container *ngIf="!isAdmin" matColumnDef="expand">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide></mat-header-cell>
                  <mat-cell *matCellDef="let row" fxShow fxHide.gt-sm class="no-padding">
                    <mat-expansion-panel class="mat-elevation-z0">
                      <mat-expansion-panel-header class="panel-width">
                        Visa mer info
                      </mat-expansion-panel-header>
                      <mat-card class="mat-elevation-z0">
                        <mat-card-content class="content">
                          <div>
                            <span class="mobile-label">Faktura:&nbsp;</span>
                            <a *ngIf="row.InvoiceDocument" href="{{row?.InvoiceDocument?.Url}}" title="{{row?.InvoiceNumber}}" target="_blank">{{row?.InvoiceNumber}}</a>
                          </div>
                          <div>
                            <span class="mobile-label">Lönestatus:&nbsp;</span>
                            <span *ngIf="!isAdmin">{{row?.PayoutStatus?.Name}}</span>
                          </div>
                          <div>
                            <span class="mobile-label">Skickad:&nbsp;</span>{{row?.InvoiceSentByUser | slice:0:10}}
                          </div>
                          <div>
                            <a style="margin-top: 10px; font-weight: bold;" *ngIf="row.InvoiceDocument && row.Status.TechnicalName != 'UnderReview'" href="{{row?.InvoiceDocument.Url}}" title="{{row?.InvoiceNumber}}" target="_blank">Se fakturan</a>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </mat-expansion-panel>
                  </mat-cell>
                </ng-container>

                <ng-container *ngIf="isAdmin" matColumnDef="customerNameMobileAdmin">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide></mat-header-cell>
                  <mat-cell *matCellDef="let row" class="mobile-class" fxHide.gt-sm>
                    <span class="mobile-label">Kund:&nbsp;</span>{{row?.Customer?.Name}}
                    <button mat-icon-button color="default" [matMenuTriggerFor]="menu">
                      <mat-icon color="accent">reorder</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" color="accent">
                      <button mat-menu-item (click)="editInvoice(row)"><mat-icon>edit</mat-icon>Redigera</button>
                      <button mat-menu-item (click)="openAdminView(row)"><mat-icon>swap_horiz</mat-icon>Admin</button>
                      <button mat-menu-item (click)="showSummary(row)"><mat-icon>money</mat-icon>In- och utbetalning</button>
                      <button mat-menu-item (click)="creditInvoice(row)" [disabled]="row?.InvoiceNumber == null"><mat-icon>money_off</mat-icon>Kreditera/radera</button>
                      <button mat-menu-item (click)="openWorkCertificateView(row)"><mat-icon>drafts</mat-icon>Arbetsgivarintyg</button>
                      <button mat-menu-item (click)="copyInvoice(row)" [disabled]="row?.SubInvoice || row?.CreditInvoiceFor > 0"><mat-icon>file_copy</mat-icon>Kopiera till ny faktura</button>
                      <button mat-menu-item (click)="deleteInvoice(row)" [disabled]="row?.Status?.Name != 'Utkast'"><mat-icon>delete</mat-icon>Radera</button>
                    </mat-menu>
                  </mat-cell>
                </ng-container>

                <ng-container *ngIf="isAdmin" matColumnDef="expand">
                  <mat-header-cell *matHeaderCellDef mat-sort-header fxHide></mat-header-cell>
                  <mat-cell *matCellDef="let row" fxShow fxHide.gt-sm class="no-padding">
                    <mat-expansion-panel class="mat-elevation-z0">
                      <mat-expansion-panel-header class="panel-width">
                        Visa mer info
                      </mat-expansion-panel-header>
                      <mat-card class="mat-elevation-z0">
                        <mat-card-content class="content">
                          <div>
                            <span class="mobile-label">Faktura:&nbsp;</span>
                            <a *ngIf="row.InvoiceDocument" href="{{row?.InvoiceDocument?.Url}}" title="{{row?.InvoiceNumber}}" target="_blank">{{row?.InvoiceNumber}}</a>
                          </div>
                          <div>
                            <span class="mobile-label">Användare:&nbsp;</span> {{row?.UserSettings?.FirstName}} {{row?.UserSettings?.LastName}}
                          </div>
                          <div>
                            <span class="mobile-label">Lönestatus:&nbsp;</span>
                            <mat-select *ngIf="isAdmin" placeholder="Lönestatus" [(ngModel)]="row.PayoutStatus.Id" style="width: 130px;" (ngModelChange)="payoutStatusChange(row)">
                              <mat-option *ngFor="let s of payoutStatuses" [value]="s.Id">
                                {{ s?.Name }}
                              </mat-option>
                            </mat-select>
                          </div>
                          <div>
                            <span class="mobile-label">Skickad:&nbsp;</span>{{row?.InvoiceSentByUser | slice:0:10}}
                          </div>
                          <div>
                            <a style="margin-top: 10px; font-weight: bold;" *ngIf="row.InvoiceDocument && row.Status.TechnicalName != 'UnderReview'" href="{{row?.InvoiceDocument.Url}}" title="{{row?.InvoiceNumber}}" target="_blank">Se fakturan</a>
                          </div>
                        </mat-card-content>
                      </mat-card>
                    </mat-expansion-panel>
                  </mat-cell>
                </ng-container>

                <!--End Mobile Rows -->
              </div>

              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;">
              </mat-row>
            </mat-table>
          </div>

          <mat-paginator [pageSizeOptions]="[25,50,100]" [pageSize]="10" [length]="listLength" (page)="onPaginatorChange(paginator)" showFirstLastButtons="true"></mat-paginator>
        </div>

      </div>


    </div>
  </div>
</div>
